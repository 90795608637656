import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    profile: false
  },
  mutations: {
    Logged:function(state){
      state.profile = true
    },
    Logout:function(state){
      state.profile = false
    }
  },
  actions: {
    DoLogout: ({ commit }) => {
      commit('Logout')
      router.push('login')
    },
    DoLogged: ({ commit }) => {
      commit('Logged')
      router.push('/')
    }
  },
  getters:{
    Authenticated: state => !!state.profile,
    Profile: state => state.profile
  },
  modules: {
  }
})
