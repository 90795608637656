import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'

Vue.use(VueRouter)
const Allow = (_to: any, _from: any, next:any) => {
  if (!store.getters.Authenticated) {
    next()
    return
  }
  next('/')
}
const Deny = (_to: any, _from: any, next:any) => {
  if (store.getters.Authenticated) {
    next()
    return
  }
  next('/login')
}
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: ()=> import('../views/Home.vue'),
    beforeEnter: Deny
  },
  {
    path: '/login',
    name: 'Login',
    component: ()=> import('../views/Login.vue'),
    beforeEnter: Allow
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

export default router
