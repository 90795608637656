import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { firestorePlugin } from 'vuefire'
import Toast, { POSITION }  from "vue-toastification";
import "vue-toastification/dist/index.css";
import { auth } from '@/plugins/auth'
import VueClipboard from 'vue-clipboard2'
const options = {
  timeout: 2000,
  position: POSITION.TOP_RIGHT
}
auth.onAuthStateChanged(user => {
  if(user){
    store.dispatch("DoLogged")
  }else{
    store.dispatch("DoLogout")
  }
})
Vue.use(Toast, options)
Vue.use(firestorePlugin)
Vue.use(VueClipboard)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
